
import { defineComponent, ref, reactive, onMounted } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ErrorMessage, Field, Form } from "vee-validate";

import ApiService from "@/core/services/ApiService";
import { useToast } from "vue-toastification";
import { useRouter, useRoute } from "vue-router";
import { string } from "yup/lib/locale";

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import Multiselect from "@vueform/multiselect";
import { Modal } from "bootstrap";

interface NewData {
  file: any;
  fileName: string;
}

interface items {
  listSekolah: any;
  disableButton: boolean;
  fileInputKey: any;
  errorfile: string,

  total: string,
  valid: string,
  unValid: string,
  unValidData: any,

  role: string,
}

export default defineComponent({
  name: "new-target-modal",
  components: {
    Loading, 
    Multiselect,
    Field,
  },
  setup() {
    const formRef = ref<null | HTMLFormElement>(null);
    const uploadModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);

    const file = String;

    const toast = useToast();
    const router = useRouter();

    const isLoading = ref(false);
    const fullPage = ref(true);
    const isColor = "#007BFF";
    const isBackgroundColor = "#A3A3A3";
    const isOpacity = 0.5;

    let items = reactive<items>({
      listSekolah: [],
      disableButton: false,
      fileInputKey: 0,
      errorfile: "",

      total: "",
      valid: "",
      unValid: "",
      unValidData: [],

      role: "",
    });

    const targetData = reactive<NewData>({
      file: "",
      fileName: "",
    });

    function handleFileUpload(event) {
      targetData.file = event.target.files[0];
      targetData.fileName = event.target.files[0].name
    }

    const submit = () => {

      if (
        targetData.file === "" 
      ) {
        items.errorfile = "File Dokumen Wajib Diisi";
      } else {
        items.errorfile = "";

        isLoading.value = true;

        items.disableButton = true;
        loading.value = true;

        // setTimeout(() => {
          let formData = new FormData();

          formData.append("schoolFile", targetData.file);

          ApiService.postWithData(
            "crmv2/main_school/school/school/upload_school_excell",
            formData
          )
            .then((res) => {
              loading.value = false;
              targetData.file = null;
              targetData.file = "";
              targetData.fileName = "";
              items.fileInputKey++

              hideModal(uploadModalRef.value);
              items.disableButton = false;

              items.total = res.data.total;
              items.valid = res.data.valid;
              items.unValid = res.data.unValid;
              items.unValidData = res.data.unValidData;

              // location.reload();
              isLoading.value = false;

              const modal = new Modal(document.getElementById("kt_modal_response_upload"));
              modal.show();

            })
            .catch((e) => {
              if (e.response.status == 401) {

                targetData.file = null;
                targetData.file = "";
                targetData.fileName = "";
                items.fileInputKey++
                
                toast.error(e.response.data.detail);
                router.push('/sign-in');
                isLoading.value = false;
              } else {

                targetData.file = null;
                targetData.file = "";
                targetData.fileName = "";
                items.fileInputKey++
                
                loading.value = false;
                items.disableButton = false;
                toast.error(e.response.data.detail);
                isLoading.value = false;
              }
            });
      }
    };

    const templateExcel = () => {
      window.open(
        "https://drive.google.com/uc?export=download&id=1_CK0g49egZwR_vK21M6OgI6m3o-xi-r9",
        "_blank"
      );
    };

    const callModal = () => {
      const modal = new Modal(document.getElementById("kt_modal-upload_sekolah"));
      modal.show();
    };

    const batalUpload = () => {
      targetData.file = null;
      targetData.file = "";
      targetData.fileName = "";
      items.fileInputKey++

      items.errorfile = "";
    };

    const kembaliSekolah = () => {
      location.reload();
    };

    onMounted(() => {
      items.role = JSON.parse(localStorage.getItem("user_account")!).role;
    });

    return {
      targetData,
      submit,
      loading,
      formRef,
      uploadModalRef,
      handleFileUpload,
      file,
      items,

      templateExcel,
      batalUpload,
      callModal,
      kembaliSekolah,

      isLoading,
      fullPage,
      isColor,
      isBackgroundColor,
      isOpacity,

    };
  },
});
